import React from "react";

import PageWrapper from "../components/PageWrapper";

const Terms = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <a className="btn header-btn rounded-5" style={{background:"rgb(51, 57, 141", color: "white"}}  href={"https://console.nuvemfiscal.com.br"}>
                   Comece Grátis
              </a>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-10 col-md-11">
                <div className="">
                  <h2 className="text-center font-size-10 mb-8">Termos de uso e política de privacidade</h2>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
O uso dos serviços da NUVEM FISCAL oferecido pela WA2 TECNOLOGIA LTDA, inscrita no CNPJ sob o nº 46.363.985/0001-10, aqui denominada como WA2, está condicionado à aceitação e ao cumprimento dos Termos de Condições de Uso descritos abaixo.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Alertamos que todo o texto deve ser lido com atenção e, caso você não concorde com o conteúdo de nossos termos e/ou política de privacidade, não dê prosseguimento à navegação ou a utilização de nossos serviços. Recomendamos, ainda, que caso seja aceito, que você armazene ou imprima uma cópia deste documento, incluindo todos os seus termos e condições de uso.
</p>

<h3 className="font-size-9 mb-8">
1. Serviço
</h3>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
A NUVEM FISCAL trata-se da oferta de serviços de uso de software no modelo Software as a Service (SaaS). O objetivo é licenciar de forma revogável, não exclusiva e intransferível o uso dos serviços por meio da internet, incluindo os serviços de hospedagem do software, banco de dados, serviços cloud, suporte técnico e fornecimento de documentação e bibliotecas de desenvolvimento (SDKs).
</p>

<h4 className="font-size-7 mb-8">
1.1. Responsabilidade de credenciais segurança
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Durante o cadastro, o cliente determinará sua senha de acesso, sendo de sua exclusiva responsabilidade a manutenção do sigilo dessa informação.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Em caso de perda, divulgação, roubo ou identificação de uso não autorizado da senha e/ou da conta de acesso, o cliente deverá informar imediatamente a WA2, sendo que essa não se responsabiliza pelas ações ou danos que poderão ser causados pelo acesso irregular da conta de acesso por terceiros.
</p>

<h4 className="font-size-7 mb-8">
1.2. Integração com terceiros
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Alguns serviços disponibilizados dependem de integrações e dados fornecidos por terceiros. Entre alguns exemplos, podemos citar (mas não limitado a esses):
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
<ul>
<li>Consulta de CEP: As informações são fornecidas por sites de terceiros, com os Correios.</li>
<li>Consulta de CNPJ: As informações são fornecidas pela Receita Federal.</li>
<li>Emissão de documentos fiscais: As operações (como autorização, cancelamento) são efetuadas pelos sistemas e APIs fornecidos e mantidos pelo Governo Federal e Prefeituras.</li>
</ul>
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
A NUVEM FISCAL não pode se responsabilizar pela interrupção parcial ou permanente dos serviços descritos acima ou quaisquer outros por ela integrados, bem como pelo mau funcionamento desses serviços. 
</p>

<h4 className="font-size-7 mb-8">
1.3. Autorização para suporte, teste e desenvolvimento
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Com o objetivo de melhorar nossos produtos e serviços bem como fornecer suporte técnico rápido e de qualidade, o cliente permite que a equipe da NUVEM FISCAL acesse informações de sua conta, realize operações utilizando suas credenciais ou utilizando certificados digitais cadastrados em seu nome, somente e exclusivamente para fins de teste, suporte e desenvolvimento.
</p>

<h3 className="font-size-9 mb-8">
2. Concessão de Licença e Restrições de Uso.
</h3>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Concessão de Licença. A WA2 lhe concede uma licença não-exclusiva e não-transferível, revogável, limitada ao acesso e uso dos serviços via web/internet, em estrita conformidade com os termos e condições desta Licença e com as Regras de Utilização e qualquer acordo de serviço relacionado com seus dispositivos (coletivamente, “acordos relacionados”).
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Restrições de uso. Você deve usar os serviços em estrita conformidade com os termos dos respectivos contratos e não deve: (a) descompilar, fazer engenharia reversa, desmontar, tentar obter o código fonte, ou decifrar o Aplicativo; (b) fazer qualquer modificação, adaptação, melhoria, aperfeiçoamento, tradução ou trabalho derivado de Aplicação; (c) violar quaisquer leis, regras e regulamentos em relação ao seu acesso ou utilização da Aplicação; (d) remover, alterar ou ocultar qualquer aviso de propriedade (incluindo qualquer aviso de copyright ou marca) da WA2 ou NUVEM FISCAL; (e) usar os serivços para qualquer empreendimento de geração de receita, empresa comercial, ou outra finalidade para a qual ele não foi projetado ou previsto; (f) disponibilizar o acesso aos serviços através de uma rede ou outro ambiente que permitam o acesso ou uso por vários dispositivos móveis ou usuários que não tenham contratado o serviço.
</p>

<h3 className="font-size-9 mb-8">
3. Direitos de Propriedade Intelectual
</h3>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Direitos da aplicação. Você reconhece e concorda que a aplicação e todos os direitos autorais, patentes, marcas, segredos comerciais e outros direitos de propriedade intelectual que lhe estão associados são, e devem permanecer, de propriedade da WA2. Além disso. A você não são concedidos quaisquer direitos de propriedade intelectual ou para os serviços, por implicação, preclusão ou de outra teoria legal, e todos os direitos sobre os serviços não concedidos expressamente nesta licença ficam reservadas e mantidas em nome de WA2.
</p>

<h3 className="font-size-9 mb-8">
4. Restrição à Transferência
</h3>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Você não pode alugar, arrendar, emprestar, sub-licenciar ou transferir os direitos de uso dos serivços, esta Licença ou qualquer dos direitos concedidos. Qualquer tentativa de transferência, em violação desta disposição será nula e de nenhum valor ou efeito.
</p>

<h3 className="font-size-9 mb-8">
5. Política de Privacidade
</h3>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Esta Política, que tem como principal objetivo demonstrar o compromisso em tratar os seus dados pessoais com segurança, privacidade e transparência, será aplicável a você imediatamente, assim que você entrar em nosso site, contratar os nossos serviços ou operar algum de nossos sistemas.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Portanto, aqui você encontra informações sobre como essas regras se aplicam a indivíduos que interagem com os serviços e/ou produtos da WA2 e descreve como tratamos os dados pessoais coletados. Explicamos como eles são usados, armazenados e compartilhados e quais são os seus direitos em relação a esses dados. Recomendamos que você leia atentamente esta política e suas eventuais atualizações, antes de prosseguir com o uso de nossos serviços e produtos.
</p>

<h4 className="font-size-7 mb-8">
5.1. Definições Importantes
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Relacionamos o significado de alguns termos constantes da nossa Política de Privacidade para sua melhor compreensão. Estes termos, sempre que usados com a primeira letra em maiúsculo, terão o significado estabelecido abaixo:
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Autoridade Nacional de Proteção de Dados ou ANPD: consiste no órgão da administração pública responsável por zelar, implementar e fiscalizar o cumprimento da LGPD. Criada pela Medida Provisória nº 869/18, a ANPD é vinculada ao Poder Executivo Federal.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Controlador: é a empresa responsável por tomar as decisões sobre o tratamento dos seus dados pessoais. Para os fins desta Política, a WA2 será a Controladora de seus dados.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Dados de Acesso: significam informações coletadas de visitantes através do Site, pelo seu uso e acesso criando, quando possível e aplicável, o log (registro de atividades efetuadas no Site) que conterão: o endereço de IP, acesso e ações realizadas no serviço disponibilizado, data e hora de cada ação, tempo médio gasto, informações sobre o dispositivo utilizado (navegador e versão), além de Cookies.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Dado Pessoal: significa toda informação relacionada a uma pessoa física identificada ou identificável, portanto, todo aquele dado que identifique uma pessoa física, ou que, através da junção de vários tipos de dados, possa vir a identificar uma pessoa física, incluindo os Dados de Acesso. Informação da empresa ou de terceiros que não estejam disponíveis para o público.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Tratamento: corresponde a toda atividade realizada com Dados Pessoais, desde a sua coleta até sua eliminação. Assim, de forma bem genérica, toda atividade realizada pela WA2 com Dados Pessoais é considerada como Tratamento. Ex: coleta, produção, recepção, classificação, utilização, acesso, reprodução, eliminação, processamento, distribuição, extração, difusão, comunicação, transferência, armazenamento e arquivamento.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Encarregado ou Data Protection Officer (DPO): é a pessoa indicada pelo Controlador para atuar como um canal de comunicação entre o Controlador, os Titulares dos Dados Pessoais e a ANPD.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Legislação Aplicável de Proteção de Dados: significa a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados ou LGPD), suas alterações posteriores e quaisquer outras leis e regulamentos em relação ao tratamento, proteção e privacidade de Dados Pessoais aplicáveis e, se aplicáveis, todas as orientações, normas, regras, portarias, regulamentos e códigos de prática e conduta emitidos pela Autoridade Nacional de Proteção de Dados (ANPD) ou outra autoridade de supervisão ou proteção de dados pertinente.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Operador: é o agente que realiza o Tratamento de Dados Pessoais em nome do Controlador. Quando a Companhia contrata uma agência de publicidade, ou uma agência de viagens, por exemplo, eles atuam como operadores dos dados que recebe da Companhia.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Site: significa o site https://nuvemfiscal.com.br e seus subdomínios.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Violação de Dados Pessoais: significa uma violação de segurança da informação que acarreta a destruição acidental ou ilegal, perda, alteração, divulgação ou acesso não autorizado a Dados Pessoais transmitidos, armazenados ou de outra forma tratados pela WA2 ou um subcontratado autorizado.
</p>

<h4 className="font-size-7 mb-8">
5.2. Como nós coletamos seus Dados
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Nós coletamos seus Dados Pessoais sempre que você:
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Navegar no Site, Blog ou Fórum da NUVEM FISCAL;<br />
Contratar ou utilizar nossos serviços ou produtos;<br />
Utilizar a plataforma da NUVEM FISCAL;<br />
Disponibilizar seus Dados Pessoais no Site, no Blog ou Checkout (contratação) para utilizar nossas ferramentas;<br />
Inscrever-se na newsletter ou mailings da NUVEM FISCAL<br />
Disponibilizar seus Dados Pessoais a algum colaborador da NUVEM FISCAL, caso seja cliente em prospecção.<br />
</p>

<h4 className="font-size-7 mb-8">
5.3. Informações que coletamos
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Os Dados Pessoais coletados pela WA2 têm diversas fontes e estão divididos pelas seguintes categorias:
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
a) Dados pessoais informados pelo Titular:
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Dados cadastrais como: nome, documentos de identificação (CPF e RG), endereço, data de nascimento, entre outros;<br />
Dados de contato, como telefone e e-mail; e<br />
Dados bancários.<br />
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
b) Dados de navegação e do dispositivo
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Endereço IP do dispositivo utilizado para acessar os serviços ou produtos;<br />
Interações realizadas e perfil de uso do Site, Blog e dos serviços contratados; Registro das operações realizadas no sistema, que incluem informações como, mas não limitadas a: documentos fiscais emitidos, impostos recolhidos, produtos e serviços constantes no documento fiscal, números de NCM, códigos fiscais, valores, quantidades, dados dos prestadores e tomadores. <br />
Dados técnicos, como informações de URL, de conexão de rede, do provedor, e do dispositivo, ping, latência de rede, e as demais informações sobre você que possam ser extraídas dos dados obtidos junto ao seu provedor;<br />
Cookies;<br />
Atributos do dispositivo, tais como UUID do aparelho, IMEI, UUID e nome do usuário do sistema operacional, número serial do disco, tipo do navegador internet e modelo;<br />
Informações sobre geolocalização.<br />
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
c) Dados necessários ao funcionamento dos serviços
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Informações inerentes ao funcionamento dos serviços, como:
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
d) Dados públicos
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Podemos coletar informações sobre você que estejam disponíveis publicamente ou que foram tornadas públicas por você;<br />
Informações sobre menções ou interações; e<br />
Depoimentos referentes à WA2 ou NUVEM FISCAL postados em perfis e páginas nas redes sociais, juntamente com seu nome e imagem (incluindo fotos de perfil).<br />
</p>

<h4 className="font-size-7 mb-8">
5.4. Como usamos seus Dados Pessoais
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Utilizamos seus Dados Pessoais para o cumprimento de nossas obrigações legais e regulatórias perante as autoridades que supervisionam as atividades de pagamentos no Brasil (art. 7º, II da LGPD), bem como para fornecer nossos serviços e produtos de forma satisfatória e para garantir segurança e transparência das operações perante você e as autoridades brasileiras. Além disso, seus Dados Pessoais também são importantes para manutenção e implementações de novas funcionalidades que você possa precisar, pois usamos essas informações para garantir que tudo funcione como o esperado por você e para desenvolver serviços e produtos mais adequados às suas necessidades.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Conforme a LGPD estabelece, o Tratamento de Dados Pessoais deve obedecer ao princípio da finalidade, devendo ser feito somente mediante propósitos legítimos, específicos, explícitos que devem ser informados a você, sem possibilidade de Tratamento posterior de forma incompatível com essas finalidades.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Relacionamos abaixo todas as finalidades do Tratamento de seus Dados Pessoais realizado pela WA2, de acordo com a base legal determinadas pela Legislação Aplicável de Proteção de Dados.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Finalidades
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Prestação dos serviços e disponibilização dos produtos contratados;<br />
Verificação da sua identidade e elegibilidade, autenticação e verificação de requisitos para contratação dos serviços e produtos da WA2;<br />
Atendimento de solicitações e dúvidas;<br />
Contato por telefone, e-mail, SMS, WhatsApp, ou outros meios de comunicação, inclusive para envio de notificações ou push de uso dos serviços da WA2;<br />
Autenticação de transações financeiras;<br />
Marketing, prospecção, promoção dos nossos produtos e serviços, ou de nossos parceiros, incluindo envio de informações, novidades, funcionalidades, conteúdos, notícias e demais eventos relevantes para a manutenção do relacionamento com você;<br />
Aperfeiçoar os serviços prestados, inclusive para disponibilização de novos produtos e serviços;<br />
Prevenção e resolução de problemas técnicos ou de segurança;<br />
Medidas de prevenção e combate a ilícitos, fraudes, crimes financeiros e garantia da segurança dos clientes;<br />
Exercício regular de direitos da WA2, inclusive apresentando documentos em processos judiciais e/ou administrativos, se necessário;<br />
Colaboração ou cumprimento de ordem judicial, de autoridade competente ou de órgão fiscalizador;<br />
Geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades e comportamento no uso dos produtos e/ou serviços.<br />
</p>

<h4 className="font-size-7 mb-8">
5.5. Retenção e armazenamento dos Dados
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Para cumprirmos com as finalidades descritas nesta Política de Privacidade, seus Dados Pessoais serão armazenados e mantidos de forma segura e em um ambiente controlado enquanto você for um cliente ou cliente em prospecção da WA2. Caso deseje excluir seus dados, responderemos sua solicitação em até 15 (quinze) dias úteis. Apenas lembre-se que a WA2 poderá armazenar determinados dados por um período adicional para fins de auditoria, cumprimento de obrigações legais e/ou regulatórias ou exercício regular de direitos, nos termos que a legislação vigente aplicável exigir.
</p>

<h4 className="font-size-7 mb-8">
5.6. Compartilhamento de informações
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Seus Dados Pessoais poderão ser compartilhados com terceiros parceiros, autoridades e órgãos reguladores para diferentes finalidades, quando necessário. Podemos compartilhar seus dados com fornecedores de serviços de tecnologia da informação, de atendimento ao consumidor, de comunicação, de serviços estatísticos, de pesquisas, marketing, serviços financeiros, agências de cobrança, de crédito e prevenção a fraudes, bancos, instituições financeiras e outros terceiros, tendo o compartilhamento como principais finalidades àquelas elencadas acima.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Todo compartilhamento é efetuado dentro dos limites e finalidades dos nossos serviços e em estrita conformidade com a Legislação Aplicável de Proteção de Dados.
</p>

<h4 className="font-size-7 mb-8">
5.7. Seus direitos como Titular de Dados Pessoais
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Se para cumprimento de obrigação legal ou regulatória, pelo prazo indicado na norma em questão;
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Se para o cumprimento de contrato, até o alcance dos objetivos da relação contratual; e
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Se fundado no legítimo interesse, que sempre será precedido de uma análise de impacto de privacidade e realizado dentro dos limites da finalidade que o justifica, até que este cesse.  Os Usuários poderão gerenciar o uso de seus dados, podendo para tanto:
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
<ul>
<li>(i) Pedir a exclusão de seus dados, ressalvado impeditivo legal;</li>
<li>(ii) Pedir a alteração ou correção de seus dados;</li>
<li>(iii) Pedir informações sobre o compartilhamento de seus dados;</li>
<li>(iv) Restringir, na medida do possível, o uso de seus dados, inclusive no que diz respeito ao recebimento de campanhas publicitárias e utilização de Cookies;</li>
<li>(v) Solicitar cópia de seus dados em formato legal e adequado;</li>
<li>(vi) Revogar o consentimento para o tratamento de seus dados, sendo avisado neste caso sobre as consequências;</li>
<li>(vii) Solicitar que a WA2 cesse o tratamento de seus dados para fins de marketing através dos links apropriados para tal fim, informados nas próprias mensagens de marketing.</li>
</ul>
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
A WA2 atenderá aos pleitos dos Usuários, mas informa que esses direitos não serão absolutos, de modo que algumas situações podem resultar em atendimento parcial do pedido ou até negativa. Nessas situações, prestará os esclarecimentos e justificativas necessárias.<br />
</p>

<h4 className="font-size-7 mb-8">
5.8. Registro de Atividades
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Podemos registrar as atividades que você realiza quando utiliza nosso Site, Fórum e/ou Blog criando os Dados de Acesso. Nós também podemos utilizar Cookies e tecnologias similares, próprias ou de terceiros, de monitoramento das atividades realizadas enquanto você acessa nosso Site, Fórum e/ou Blog. Todas as tecnologias utilizadas por nós sempre respeitarão os termos desta Política de Privacidade.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Cookies são arquivos de internet que armazenam de forma temporária o que você está visitando na rede, viabilizando uma experiência personalizada de acesso.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Nós possuímos Cookies de terceiros ativados em nosso Site. Nesse caso, apenas alertamos que as práticas de privacidade serão regidas pelas políticas de privacidade e pelos termos de uso desses terceiros, portanto, para entender como estes terceiros usam Cookies, recomendamos analisar a política de privacidade destes.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Nós utilizamos os Cookies para fins de autenticação, segurança e integridade do produto, publicidade, recomendações, medições, insights, performance, análise e pesquisa que nos permitem entender o comportamento de uso do Site para aperfeiçoar nossos serviços e oferecer a melhor experiência possível.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Você pode, a qualquer momento, bloquear o uso dos Cookies ativando uma configuração no seu navegador de internet e sua capacidade de limitar os Cookies estará sujeita às configurações e limitações do seu navegador. Você também pode excluir os Cookies existentes através das mesmas configurações do seu navegador de internet. Caso você opte por desativar os Cookies, você poderá continuar navegando nos sites e nos blogs, mas algumas partes das páginas poderão deixar de funcionar.
</p>

<h4 className="font-size-7 mb-8">
5.9. Transferência internacional de Dados Pessoais
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
A WA2 poderá transferir para o exterior parte de Dados Pessoais, ou todos eles, quando são armazenados em servidores de computação em nuvem localizados fora do Brasil. Para isso, a WA2 realiza a transferência de acordo com os direitos do Titular e do regime de proteção de dados previstos pela Legislação Aplicável de Proteção de Dados, bem como adota as melhores práticas de segurança e privacidade para garantir a integridade e confidencialidade dos seus Dados Pessoais.
</p>

<h4 className="font-size-7 mb-8">
5.10. Segurança
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Seus Dados Pessoais são tratados com segurança e cuidado. Nós aplicamos os padrões de segurança e melhores práticas adotados no mercado para garantir a integridade de seus dados e impedir uma Violação de Dados Pessoais. Não obstante seus dados estarem seguros com a WA2, será de sua responsabilidade manter o login e a senha de acesso ao Site, Fórum e ao Blog seguras, bem como as credenciais de acesso aos serviços, não devendo compartilhar estas informações a ninguém. Caso acredite que, por qualquer razão, seu login e senha de acesso tenham sido roubados ou sejam de conhecimento de outras pessoas, você deverá comunicar imediatamente à WA2 através dos canais de comunicação disponíveis, bem como alterar a senha através do Site, além de revogar as credenciais de acesso aos serviços.
</p>

<h4 className="font-size-7 mb-8">
5.11. Consentimento
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Com a finalidade de garantir que você tenha completo conhecimento e consciência sobre os assuntos abordados nesta Política, ao acessar o Site ou ler esta Política de Privacidade e clicar, ao final, onde aplicável, em “Aceito os Termos de Uso e a Política de Privacidade”, ou ao aceitar utilizar os nossos serviços e produtos, você consente expressamente com os termos desta Política de Privacidade, e com a coleta e tratamento de seus Dados Pessoais.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Para tanto, você consente, livre e expressamente, em fornecer os dados que permitam o acesso às informações necessárias para que o software execute todas as funções para as quais foi projetado.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Você consente que, ao acessar o site da NUVEM FISCAL, esta poderá coletar informações técnicas de navegação, tais como tipo de navegador do computador utilizado para acesso ao site, endereço de protocolo de Internet, páginas visitadas e tempo médio gasto no site. Tais informações poderão ser usadas para lhe orientar e melhorar os serviços ofertados.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Você consente livre e expressamente que suas informações poderão ser transferidas a terceiros em decorrência da venda, aquisição, fusão, reorganização societária ou qualquer outra mudança no controle da WA2. A WA2, contudo, compromete-se, nestes casos, a informar você.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Você consente livre e expressamente que a WA2 utilize cookies apenas para controlar a audiência e a navegação em seu site e possibilitar a identificação de serviços segmentados e personalizados ao perfil dvocê. A WA2 garante que estas informações coletadas por meio de cookies são estatísticas e não pessoais, bem como que não serão utilizadas para propósitos diversos dos expressamente previstos neste EULA, comprometendo-se a adotar todas as medidas necessárias a fim de evitar o acesso e o uso de tais informações por quaisquer terceiros, sem a devida autorização.
</p>

<h4 className="font-size-7 mb-8">
5.12. Atualizações da Política de Privacidade
</h4>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
A WA2 se compromete a reavaliar sua Política de Privacidade e adaptá-la sempre que necessário. Toda vez que uma alteração relevante for feita e sejam implementadas mudanças nesta Política, a versão vigente será divulgada em nosso Site e você será notificado a respeito.
</p>

<h3 className="font-size-9 mb-8">
6. Prazo e Rescisão
</h3>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Prazo. Esta Licença entra em vigor até que seja rescindida.
</p>

<p className="font-size-5 pr-md-10 pr-lg-0 mb-8">
Rescisão. WA2 poderá, a seu critério exclusivo e absoluto, a qualquer momento e por qualquer motivo ou sem motivo, suspender ou cancelar esta Licença e os direitos concedidos a você por este instrumento com ou sem aviso prévio. Além disso, se você deixar de cumprir com todos os termos e condições desta Licença, então esta Licença e quaisquer direitos concedidos a Você abaixo cessará automaticamente, sem qualquer aviso ou outra ação pela WA2. Com o término desta Licença, você deve deixar de utilizar a Aplicação e/ou desinstalar o aplicativo.
</p>




                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Terms;
